<template>
<div>
    <back-button to="/admin/">Back to Settings</back-button>
    <b-card style="max-width: 1000px" :style="loading ? 'cursor:wait' : 'cursor:default'">
    <div>
        <b-alert
            v-model="showAlert"
            :variant="success ? 'success' : 'danger'"
            dismissible>
            <b>{{alertMessage}}</b>
        </b-alert>
    </div>
    <div class="avo-header-text mb-4">
        Taxonomy CSV Upload
    </div>
    <b-form @submit="onSubmit">
        <b-form-group>
            <b-form-file
            v-model="file"
            :state="Boolean(file)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            ></b-form-file>
            <div class="mt-3">Selected file: {{ file ? file.name : '' }}</div>
        </b-form-group>
        <div class="text-right">
            <b-button @click="onSubmit" class="avo-primary-btn" :disabled="loading">Submit</b-button>
        </div>
    </b-form>
    </b-card>
</div>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'
import BackButton from '../../components/BackButton.vue'

export default {
    name: 'TaxonomyCSVUpload',
    components: {
        BackButton
    },
    mixins: [alertsMixin],
    data() {
        return {
            file: null,
            loading: false,
            success: false,
        }
    },
    
    methods: {
        onSubmit() {
            this.loading = true;
            this.hideAlert();
            if(this.isEmpty()) {
                this.alert("Please select a file");
                return
            }
            if(this.file.size > 1024 * 1024 * 5) {
                this.alert("File size should be less than 5MB");
                return
            }
            const url = make_url("/taxonomy_codes/csv");
            const vm = this;
            var bodyFormData = new FormData();
            bodyFormData.append('taxonomy_csv', vm.file)
            const promise = authenticated_request({
                method: "post",
                url: url,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: bodyFormData
            }).then(function(response){
                vm.loading = false;
                console.log(response);
                vm.successAlert("Successfully uploaded taxonomy code CSV.\nYour import will continue processing in the background.");
                vm.file = null;
            }, function(error){
                vm.loading = false;
                vm.alert(error);
            });
            return promise;
        },
        resetModal(){
            this.file = null
        },
        isEmpty() {
            return Boolean(!this.file)
        },
        alert(message){
            this.success = false;
            this.setShowAlert(true, message);
        },
        successAlert(message){
            this.success = true;
            this.setShowAlert(true, message);
        },
        hideAlert(){
            this.success = false;
            this.setShowAlert(false);
        }
    },
}
</script>
